class OiSnapSlider {

    private slider: HTMLElement | null
    private track: HTMLElement | null
    private prev: HTMLElement | null
    private next: HTMLElement | null

    constructor(snapSlider: HTMLElement) {
        this.slider = snapSlider || null;
        this.track = snapSlider.querySelector("[data-slider-track]") || null;
        this.prev = snapSlider.querySelector("[data-slider-prev]") || null;
        this.next = snapSlider.querySelector("[data-slider-next]") || null;

        if (this.track) {
            if(this.prev) {
                this.prev.addEventListener("click", this.prevClick.bind(this));
            }
            if(this.next) {
                this.next.addEventListener("click", this.nextClick.bind(this));
            }
            if(this.track) {
                this.track.addEventListener("scroll", this.trackScroll.bind(this));
            }
        }
    }

    private prevClick() {
        this.prev.removeAttribute("disabled");

        console.log('click')
        this.track.scrollTo({
            left: this.track.scrollLeft + (this.track.firstElementChild as HTMLElement).offsetWidth,
            behavior: "smooth"
        });
    }

    private nextClick() {
        this.next.removeAttribute("disabled");

        this.track.scrollTo({
            left: 300,
            behavior: "smooth"
        });
    }

    private trackScroll() {
        console.log('trackScroll')
        const trackScrollWidth = this.track.scrollWidth;
        const trackOuterWidth = this.track.clientWidth;

        this.prev.removeAttribute("disabled");
        this.next.removeAttribute("disabled");

        if (this.track.scrollLeft <= 0) {
            this.prev.setAttribute("disabled", "");
        }

        if (this.track.scrollLeft === trackScrollWidth - trackOuterWidth) {
            this.next.setAttribute("disabled", "");
        }
    }
}

export default OiSnapSlider
